import React, { createContext, useContext, useEffect } from 'react';
import { directory } from '../services/Typecode';
import { useHotel } from './HotelContext';
import hotelSpecificTranslation from '../services/translations/hotelSpecificTranslation.json';
import { ConfigProvider } from 'antd';
const LanguageContext = createContext({});
export const useLanguage = () => useContext(LanguageContext);

const LanguageContextProvider = ({ children }) => {
    const [lang, setLang] = React.useState('en');
    const [langList, setLangList] = React.useState([]);
    const [translation, setTranslation] = React.useState({});
    const { hotelDetails } = useHotel();

    React.useEffect(() => {
        const response = localStorage.getItem('lang') || 'en';
        changeHTMLDirection(response);
        setLang(response);
    }, []);
    useEffect(() => {
        if (hotelDetails.languages && hotelDetails.languages.length) {
            const enabledLanguages=hotelDetails.languages?.filter((lang)=>Boolean(lang.enabled));
            setLangList(enabledLanguages);
        }
    }, [hotelDetails, hotelDetails.languages]);

    React.useEffect(() => {
        let code = lang ?? 'en';
        changeHTMLDirection(code);
        const getTranslation = directory[code] || {};

        const hotelID = hotelDetails.id;
        const propertyObj = hotelSpecificTranslation[hotelID] || {};
        const selectedLangtranslation = propertyObj[code] || {};
        const finalTranslation = {
            ...getTranslation,
            ...selectedLangtranslation,
        };
        setTranslation(finalTranslation);
    }, [lang, hotelDetails.id]);

    React.useEffect(() => {
        const updateLanguage = () => {
            try {
                const response = localStorage.getItem('lang') || 'en';
                setLang(response);
            } catch (error) {}
        };

        updateLanguage();
    }, []);

    const changeHTMLDirection = (code) => {
        if (code === 'ar') {
            document.body.setAttribute('dir', 'rtl');
        } else {
            document.body.setAttribute('dir', 'ltr');
        }
    };
    // const renderText = (key) => {
    //     const value = translation[key]
    //     return value;
    // };
    const updateLocalStorage = async (lang) => {
        try {
            await localStorage.setItem('lang', lang);
        } catch (error) {}
    };
    const changeLanguage = (newlang, reloadApp) => {
        setLang(newlang);
        updateLocalStorage(newlang);
        if (reloadApp) window.location.reload();
    };

    const value = {
        changeLanguage,
        lang,
        langList,
        t: translation,
    };
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary:
                            hotelDetails.guest_primary_color || '#75716f',
                        algorithm: true, // Enable algorithm
                    },
                    Input: {
                        colorPrimary:
                            hotelDetails.guest_primary_color || '#75716f',
                        algorithm: true, // Enable algorithm
                    },
                },
            }}
        >
            <LanguageContext.Provider value={value}>
                {children}
            </LanguageContext.Provider>
        </ConfigProvider>
    );
};

export default LanguageContextProvider;
