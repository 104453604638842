import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, Card, Header } from "../../components/components";
import styles from "./orderFood.module.css";
import provider from "./data.provider";
import {
  IRDMENU_IMAGE_ROOT_URL,
  HOTEL_CONTENT_IMAGE_ROOT_URL,
  getPrivateResource,
} from "../../services/api";
import ReactGA from "react-ga4";
import {
  updateMenuItems,
  updateSelectedMenu,
} from "../../store/slices/irdSlice";
import { useDispatch } from "react-redux";
import { useHotel } from "../../context/HotelContext";

const OrderFoodCategoriesList = ({ items, category }) => {
  const navigate = useNavigate();
  const [itemsMenu, setItemsMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contentMenu, setContentMenu] = useState([]);
  const dispatch = useDispatch();
  const { hotelDetails } = useHotel();

  window.scrollTo(0, 0);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  // useEffect(()=> {
  //   setContentMenu(content);
  // },[content])

  useEffect(() => {
    const sorted =  items?.sort((a,b)=> b.priority - a.priority) || [];
    setItemsMenu(sorted);
  }, [items]);

  if (!itemsMenu?.length) {
    return <Loading />;
  }
  // if (category?.length >= 1) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/order",
  //         data: category,
  //         disclaimerText: itemsMenu[0].description,
  //         isViewOnly: itemsMenu[0].view_only === 1 ? true : false,
  //         banner: itemsMenu[0].banner
  //           ? IRDMENU_IMAGE_ROOT_URL + itemsMenu[0].banner
  //           : null,
  //       }}
  //     />
  //   );
  // }

  const getcategory = async (item) => {
    const hotelID = localStorage.getItem("hotel_id") || false;
    const langCode = localStorage.getItem("lang") || "en";
    dispatch(updateSelectedMenu(item));
    setIsLoading(() => true);
    const res = await getPrivateResource(
      `/ird/guest/menu/categorise/${hotelID}/${item?.id}?lang_code=${langCode}`,
      true
    );
    setIsLoading(() => false);
    const data = res?.data?.data;
    setContentMenu(data?.static_contents || []);
    if (data?.child_menus?.length) {
        const sorted = data?.child_menus?.sort((a,b)=> b.priority - a.priority) || [];
      setItemsMenu(sorted);
    } else if (data?.menu_categories?.length) {
      dispatch(updateMenuItems([]));
      navigate({
        pathname: `/order/${item?.id}`,
      });
    }
  };

  const getBannerNested = (item = false) =>
    item ? (
      <div
        key={item.id}
        className={
          `service ${item.size_type} ${item.type} ` +
          (hotelDetails?.guest_primary_color ? "apply-theme" : "")
        }
        style={{
          backgroundImage: `url(${IRDMENU_IMAGE_ROOT_URL}${encodeURIComponent(
            item.image
          )})`,
        }}
        onClick={() => {
          getcategory(item);
        }}
      >
        <div className="service_title">{item.name}</div>
      </div>
    ) : (
      <div />
    );

  const getBannerContent = (item = false) =>
    item ? (
      <div
        key={item.id}
        className={
          `service ${item.size_type} ${item.type} ` +
          (hotelDetails?.guest_primary_color ? "apply-theme" : "")
        }
        style={{
          backgroundImage: `url(${HOTEL_CONTENT_IMAGE_ROOT_URL}${encodeURIComponent(
            item.banner
          )})`,
        }}
        onClick={() => {
          navigate({
            pathname: "/content",
            data: item?.id,
          });
        }}
      >
        <div className="service_title">{item.name}</div>
      </div>
    ) : (
      <div />
    );

  return (
    <>
      <Header showBack />
        <div
          className={
            "homepage-container "
          }
        >
          <div className="allservices-container">
            {isLoading && <Loading />}
            {/* {contentMenu?.map(item => item.enabled === 1 && getBannerContent(item))} */}
            {itemsMenu.map((item) =>
              item.type === "default"
                ? item.enabled === 1 && getBannerNested(item)
                : item.enabled === 1 && getBannerContent(item)
            )}
          </div>
        </div>
    </>
  );
};

export default provider(OrderFoodCategoriesList);
