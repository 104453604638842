import React from "react";
import styles from "./card.module.css";
import { useHotel } from "../../context/HotelContext";

const Card = ({ cover, heading, jumboStyle, hotelID }) => {
  const { hotelDetails } = useHotel()

  return (
    <article className={jumboStyle ? styles.jumboCard : styles.card}>
      {cover && <img className={styles.cover} src={cover} alt={heading} />}
      {heading && (
        <div
          className={styles.headingWrapper}
        >
          <h3>{heading}</h3>
        </div>
      )}
    </article>
  );
};

export default Card;
