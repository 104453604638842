/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { getPrivateResource } from "../../services/api";

const DataProvider = (ConnectedComponent) => () => {
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  // const [menu_categories, setmenu_categories] = useState([]);

  const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
        const langCode = localStorage.getItem('lang') || 'en';

      let res = await getPrivateResource(`/ird/guest/menus-only/${hotelID}?lang_code=${langCode}`);
      // const [{ categories }] = data;
      const data = res?.data?.data;

      // if (!data?.static_contents?.length && data?.ird_menu?.length === 1) {
      //   res = await getPrivateResource(
      //     `/ird/guest/menu/categorise/${hotelID}/${data?.ird_menu?.[0]?.id}`,
      //     true
      //   );
      //   setmenu_categories(res?.data?.data?.menu_categories);
      // }
      setData(data?.all);
    }
    getEnhancedData();
  }, [hotelID]);
  // category={menu_categories}
  return <ConnectedComponent items={data} />;
};

export default DataProvider;
