import React, { useEffect, useState } from 'react';
import { WelcomeCard, Loading, Header } from '../../components/components';
import styles from './welcome.module.css';
import provider from './data.provider';
import ReactGA from 'react-ga4';

const Welcome = ({ content = [], banner }) => {
    const [selectedContent, selectContent] = useState(null);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, []);

    if (!content.length) {
        return <Loading />;
    }
    const COLOR_WITH_TYPE = 'backgroud_color';

    if (selectedContent !== null) {
        const html = content.find(
            (item) => item.id === selectedContent
        ).content;
        window.scrollTo(0, 0);
        return (
            <>
                <Header showBack backBtnHandler={() => selectContent(null)} />

                <div className={'welcome-content-view ' + styles.welcomeLayout}>
                    <div
                        className={styles.welcomeContent}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </>
        );
    }
    return (
        <>
            <Header showBack />
            <div className={'welcome-wrapper ' + styles.welcomeLayout}>
                {banner==="NO_IMAGE" ? '' : banner ? (
                    <img
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/hotel_services/${encodeURIComponent(banner)}`}
                        alt=''
                     className='welcome-cover-img'/>
                ) : (
                    localStorage.getItem('__welcome_cover') && (
                        <img
                            src={localStorage.getItem('__welcome_cover')}
                            alt=''
                        className='welcome-cover-img' />
                    )
                )}
                {content.map((element) => (
                    <WelcomeCard
                        key={Math.random()}
                        color={
                            element.backgroud_color
                                ? element.backgroud_color
                                : element[COLOR_WITH_TYPE]
                        }
                        textColor={element.font_color}
                        name={element.name}
                        description={element.description}
                        id={element.id}
                        onClick={() => selectContent(element.id)}
                    />
                ))}
            </div>
        </>
    );
};

export default provider(Welcome);
