import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { getHotelDetails } from '../services/api';

const HotelContext = createContext({});
export const useHotel = () => useContext(HotelContext);

function HotelContextProvider(props) {
    const [hotelDetails, setHotelDetails] = useState({});
    const [serviceList, setServiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        let hotelID;
        let urlHotelID = '';
        let urlRoomNumber = '';

        if (location.pathname.includes('welcome')) {
            let splitedLocation = location.pathname.split('/');
            if (
                splitedLocation.includes('welcome') &&
                splitedLocation.length >= 3
            ) {
                urlHotelID = splitedLocation[2];
                urlRoomNumber = splitedLocation[3] || '';
            }
            if (splitedLocation.length === 3) {
                if (
                    splitedLocation[1] === 'welcome' &&
                    splitedLocation[2].length > 10
                ) {
                    localStorage.setItem('hotel_id', splitedLocation[2]);
                    navigate('/', { replace: true });
                }
            }
        }
        const existingHotelID = localStorage.getItem('hotel_id');
        const existingRoomNumber = localStorage.getItem('room_no');
        const isRoomNoInUrl = urlRoomNumber ? '1' : '0';

        if (urlHotelID) {
            if (
                urlHotelID !== existingHotelID ||
                urlRoomNumber !== existingRoomNumber
            ) {
                localStorage.clear();
                localStorage.removeItem('hotel_token');
                localStorage.setItem('isRoomNoInUrl', isRoomNoInUrl);
                localStorage.setItem('hotel_id', urlHotelID);
                localStorage.setItem('room_no', urlRoomNumber);
                hotelID = localStorage.getItem('hotel_id');
            } else {
                hotelID = existingHotelID;
            }
        } else {
            hotelID = existingHotelID;
        }

        ReactGA.set({ 'Hotel Id': hotelID });
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, [location.pathname]);

    useEffect(() => {
        fetchHotelDetails();
    }, []);

    const fetchHotelDetails = async () => {
        try {
            setLoading(true);
            const response = await getHotelDetails();
            if (response.status === 200) {
                const properties = response.data.data || {};
                // properties.guest_primary_color='';
                const sevices =
                    response.data.hotel_services_with_extra_services || [];
                setServiceList(sevices);
                setHotelDetails(properties);
                localStorage.setItem('isHotelViewOnly', properties?.view_only);
                let hClass = `_${properties.id}`;
                document.documentElement.style.cssText = `--primary-color: ${
                    properties.guest_primary_color || '#75716f'
                }`;
                if (
                    document.body.classList &&
                    !document.body.classList.contains(hClass)
                ) {
                    document.body.classList.add(hClass);
                }
                if (properties.bkg_image) {
                    const imgUrl = `https://master-vserve.s3.ap-south-1.amazonaws.com/hotels/${properties.bkg_image}`;
                    document.body.style.backgroundImage = `url(${imgUrl})`;
                    document.body.style.backgroundSize = `contain`;
                }
                if (properties.custom_font) {
                    document.body.style.fontFamily = properties.custom_font;
                }
                if (location.pathname.includes('welcome')) {
                    let spiltedPath = location.pathname.split('/');
                    if (
                        spiltedPath.includes('welcome') &&
                        spiltedPath.length >= 3
                    ) {
                        navigate('/', { replace: true });
                    }
                }
            }
        } catch (error) {
            const { response, ...errorObj } = error;
            if (response.status === 401) {
                navigate('/page-not-found', { replace: true });
            }
        } finally {
            setLoading(false);
        }
    };

    const value = {
        hotelDetails,
        loading,
        serviceList,
    };
    return (
        <HotelContext.Provider value={value}>
            {props.children}
        </HotelContext.Provider>
    );
}

export default HotelContextProvider;
