import axios from 'axios';
import { getCookie } from '../helper/_cookieHelper';
//  axio header setup
axios.defaults.baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? `https://vserve-api.valet2you.in/api/v5.0.1/`
        : `https://test-vserve-api.valet2you.in/api/v5.0.1/`;

let ROOT = '';
// window.location.origin === 'https://mi.vserve-protecht.com' ||
// window.location.origin === 'https://hyatt.vserve-protecht.com' ||
// window.location.origin === 'https://accor.vserve-protecht.com' ||
// window.location.origin === 'https://raffles.vserve-protecht.com' ||
// window.location.origin === 'https://hilton.vserve-protecht.com' ||
// window.location.origin === 'https://mh.vserve-protecht.com' ||
// window.location.origin === 'https://radisson.vserve-protecht.com' ||
// window.location.origin === 'https://ihg.vserve-protecht.com' ||
// window.location.origin === 'https://nw.vserve-protecht.com' ||
// window.location.origin === 'https://patina.vserve-protecht.com' ||
// window.location.origin === 'https://nwrestaurant.vserve-protecht.com' ||
// window.location.origin === 'https://patinarestaurant.vserve-protecht.com' ||
// window.location.origin === 'https://rhr.vserve-protecht.com' ||
// window.location.origin === 'https://hiive.vserve-protecht.com'
//     ? 'https://vserve-api.valet2you.in/api/v5.0.1'
//     : 'https://test-vserve-api.valet2you.in/api/v5.0.1';

const AWS_ASSETS_ROOT_URL =
    'https://master-vserve.s3.ap-south-1.amazonaws.com/';
export const IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}hotels/`;
export const FOOD_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}menu-categories/`;
export const SERVICE_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}hotel_services/`;
export const IRDMENU_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}menus/`;
export const IRDITEM_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}menu-items/`;
export const HOTEL_CONTENT_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}hotel-static-content/`;
export const SPA_IMAGE_ROOT_URL = `${AWS_ASSETS_ROOT_URL}`;

axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getToken = () => {
    try {
        const res = localStorage.getItem('hotel_token');
        return res;
    } catch (error) {}
};
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        const token = getToken();

        if (token) {
            // config.headers.common['Authorization'] = 'Bearer ' + token;
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // if (config && config.url) {
        //     if (config.url.includes('?')) {
        //         config['url'] = `${config.url}&lang_code=${langCode}`;
        //     } else {
        //         config['url'] = `${config.url}?lang_code=${langCode}`;
        //     }
        // }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const { response, ...errorObj } = error;
        if (response.status === 401) {
            // removeUserDataFromBrowserStorage();
            // window.location.replace("/login")
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export const login = ({
    hotelId,
    email,
    name,
    last_name,
    first_name,
    mobile,
    mobile_country_code,
}) => {
    const data = {
        hotel_enc_id: hotelId,
        device_type: 'web',
        device_token: 'ok',
    };
    if (email) {
        data['email'] = email;
    }
    if (name) {
        data['name'] = name;
    }
    if (last_name) {
        data['last_name'] = last_name;
    }
    if (first_name) {
        data['first_name'] = first_name;
    }
    if (mobile) {
        data['mobile'] = mobile;
        data['mobile_country_code'] = mobile_country_code;
    }
    return axios.post(`register-guest`, data);
};

export const userData = (endpoint) => {
    return axios.get(endpoint);
};

export const getPrivateResource = (endpoint, isV5api = false) => {
    return axios.get(endpoint);
};

export const postPrivateResource = (endpoint, payload) => {
    return axios.post(endpoint, payload);
};

export const postPrivateResourceFormData = (endpoint, payload) => {
    return axios.post(endpoint, payload);
};

export const getPublicResource = (endpoint) => axios.get(endpoint);

export const getVerifyRoomNo = (room_no, isV5URL = false) => {
    const hotelID = localStorage.getItem('hotel_id') || false;
    return axios.get(`/guest/hotel/room_no/verify/${hotelID}/${room_no}`);
};

export const thanksText = () => {
    const langCode = localStorage.getItem('lang') || 'en';
    const hotelID = localStorage.getItem('hotel_id');
    return axios.get(`guest/hotel/details${hotelID}?lang_code=${langCode}`);
};

export const getAllFeeds = (hotel_id, page = 1, count = 10) => {
    const ACCESS_TOKEN = localStorage.getItem('hotel_token') || '';
    let sessionID = getCookie('_uid');
    const langCode = localStorage.getItem('lang') || 'en';
    return axios({
        method: 'GET',
        url: `feeds?hotel_id=${hotel_id}&page=${page}&per_page=${count}&lang_code=${langCode}`,
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
            'GUEST-SESSION-ID': sessionID,
        },
    });
};

export const likeFeed = (feed_id) => {
    const ACCESS_TOKEN = localStorage.getItem('hotel_token') || '';
    let sessionID = getCookie('_uid');
    return axios({
        method: 'PUT',
        url: `feeds/like/${feed_id}`,
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
            'GUEST-SESSION-ID': sessionID,
        },
    });
};
export const viewFeeds = (payload) => {
    const ACCESS_TOKEN = localStorage.getItem('hotel_token') || '';
    let sessionID = getCookie('_uid');
    return axios({
        method: 'POST',
        url: `feeds/increase-view`,
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
            'GUEST-SESSION-ID': sessionID,
        },
        data: payload,
    });
};
export const UnlikeFeed = (feed_id) => {
    const ACCESS_TOKEN = localStorage.getItem('hotel_token') || '';
    let sessionID = getCookie('_uid');
    return axios({
        method: 'PUT',
        url: `feeds/unlike/${feed_id}`,
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
            'GUEST-SESSION-ID': sessionID,
        },
    });
};

export const feedInterest = (payload) => {
    const ACCESS_TOKEN = localStorage.getItem('hotel_token') || '';
    let sessionID = getCookie('_uid');
    return axios({
        method: 'POST',
        url: `feeds/do-interest`,
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
            'GUEST-SESSION-ID': sessionID,
        },
        data: payload,
    });
};
export const getHotelDetails = () => {
    const langCode = localStorage.getItem('lang') || 'en';

    const hotelID = localStorage.getItem('hotel_id');
    return axios.get(`guest/hotel/details/${hotelID}?lang_code=${langCode}`);
};

export const getConnectData = () => {
    const langCode = localStorage.getItem('lang') || 'en';
    const hotelID = localStorage.getItem('hotel_id');
    return axios.get(`/connect/guest/menu/${hotelID}?lang_code=${langCode}`);
};
export const placeRequestOrder = (payload) => {

    return axios.post('/connect/guest/order', payload);
};
