import React, { useState, useEffect } from 'react';
import { Header, Loading } from '../../components/components';
import styles from './checkout.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import localStorage from '../../services/cart';
import { PlaceRequestProvider as provider } from './checkout.provider';
import ReactGA from 'react-ga4';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import {Button} from 'antd'
const CheckoutPlaceRequest = ({ roomNumber, placeOrder }) => {
    const [roomNo, setRoomNo] = useState(roomNumber);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state || {};
    const [guests, updateGuests] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isRoomNoInUrl, setisRoomNoInUrl] = useState(true);
    const [cartItems, setCartItems] = useState([]);
    const { translations } = useHotel();
    const { t } = useLanguage();

    useEffect(() => {
        const v = localStorage.getItem('isRoomNoInUrl');
        setisRoomNoInUrl(v === '1');
    }, []);

    useEffect(() => {
        const { selectedRequests } = data;
        let itemData = Object.keys(selectedRequests).map(
            (key) => selectedRequests[key]
        );
        setCartItems(itemData);
    }, [data]);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, []);

    if (!Object.keys(data).length) {
        navigate('/');
        return <div />;
    }
    if (loading) {
        return <Loading />;
    }

    //   const { content, selectedRequests } = data;
    //   const selectedItems = Object.keys(selectedRequests).map((key) => {
    //     const matchingContent = content?.categories?.find((cont) => cont.id == key);
    //     return {
    //     ...matchingContent,
    //     value: selectedRequests[key].value,
    //     quantity: matchingContent?.type === "with-count" ? parseInt(selectedRequests[key].value) : null
    //   }
    // })

    const placeOrderHandler = async () => {
        setLoading(true);
        setError('');
        if (!roomNo) {
            setError(t['checkout_place_request_error']);
            setLoading(false);
            return;
        }
        await localStorage.setItem('room_no', roomNo);
        let data = cartItems.map((entry) => ({
            connect_item_id: entry.id,
            description: entry.value,
            ...(entry.value && { quantity: +entry.value }),
        }));
        try {
            await placeOrder({
                items: data,
                guestNumber: guests,
            });
            setLoading(false);
            ReactGA.set({ placerequestPlaced: true });
            ReactGA.event({
                category: 'Place Request',
                action: 'Place Request',
            });
            return navigate('/request-thank-you');
        } catch (e) {
            setLoading(false);
            setError(t['checkout_place_request_error']);
            console.log(e);
        }
    };
    // console.log(selectedItems);
    return (
        <>
            <Header showBack />
            <div className='flex flex-col gap-3 p-4 pb-16'>
                {error && (
                    <div className={styles.errorMessage}>
                        {t['place_request_error']}
                    </div>
                )}
                <p className={styles.placeRequestContentIntro}>
                    {t['checkout_request_title']}
                </p>
                <ul style={{ paddingLeft: '0rem' }}>
                    {cartItems.map((item) => (
                        <li
                            key={item.id}
                            className={styles.placeRequestContent}
                        >
                            {item.name} {item.value ? ` - ${item.value}` : ''}
                        </li>
                    ))}
                </ul>

                <div className={'flex  justify-between items-center'}>
                    <label className='flex-1'>{t['room_villa_no']}</label>
                    {!isRoomNoInUrl ? (
                        <input
                            type='text'
                            value={roomNo}
                            className='roomNumberInput flex-1'
                            onChange={(e) => {
                                setRoomNo(e?.target?.value);
                            }}
                            placeholder='Room Number'
                        />
                    ) : (
                        <p>{roomNumber}</p>
                    )}
                </div>
                <div
                    className={
                        'flex justify-between items-center absolute bottom-14 left-0 w-full px-4 h-16 gap-4 bg-slate-50 border-t'
                    }
                >
                    <Button onClick={() => navigate(-1)} className='flex-1' size='large'>
                        {t['back_btn']}
                    </Button>
                    <Button
                    className='flex-1 bg-theme'
                    size='large'
                        type='primary'
                        disabled={roomNo === ''}
                        onClick={() => placeOrderHandler()}
                    >
                        {t['common_proceed_btn']}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default provider(CheckoutPlaceRequest);
