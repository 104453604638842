import React, { useState, useEffect } from 'react';
import SignatureInstance from 'signature_pad';
import { FoodProvider as DataProvider } from './checkout.provider';
import localStorage from '../../services/cart';
import {
    TextArea,
    DatePickerComponent,
    Loading,
    Header,
} from '../../components/components';
import confirmationStyles from './food.module.css';
import styles from './checkout.module.css';
import { useNavigate } from 'react-router-dom';
import upload from '../../assets/icons/upload.svg';
import ReactGA from 'react-ga4';
import { getVerifyRoomNo } from './../../services/api';
import { dataURItoBlob, printCurrencySymbol } from '../../helpers/commonHelper';
import { convertTimeUTC } from './timeUTCConvertor';
import {
    generateCartItem,
    getLocalCartItems,
    makeLocalCartItemsEmpty,
    removeLocalCartItem,
    renderPrice,
    updateAllCartItem,
} from '../../helper/commonHelper';
import timeConfig from './timeConfig';
import CheckoutAddonRepeatConfirm from '../orderFood/CheckoutAddonRepeatConfirm';
import AddonComponent from '../orderFood/AddonComponent';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import {
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Switch, Checkbox } from 'antd';
import Signature from '../../components/Signature';
const ButtonGroup = Button.Group;

const dateConfig = {
    year: {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    month: {
        format: 'M',
        caption: 'Month',
        step: 1,
    },
    date: {
        format: 'D',
        caption: 'Day',
        step: 1,
    },
    min: new Date(),
    max: new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
};
const Checkout = ({ defaultEntries = [], submitRequest, roomNumber }) => {
    const [roomNo, setRoomNo] = useState(roomNumber);
    const [instructions, updateInstructions] = useState('');
    const [guests, updateGuests] = useState(0);
    const [PUDate, setPUDate] = useState('');
    const [PUHour, setPUHour] = useState('');
    const [preOrderToggle, setPreOrderToggle] = useState(false);
    const [entries, setEntries] = useState(defaultEntries);
    const sessionID = localStorage.initiate();
    const [signaturePad, setSignaturePad] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const [confirmNow, setConfirmationMethod] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const { hotelDetails } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const { t } = useLanguage();

    const [multiSelectPaymentOption, setMultiSelectPaymentOption] = useState(
        []
    );
    const [singleSelectPaymentOption, setSingleSelectPaymentOption] =
        useState(null);
    const [outOfStockItems, setOutOfStockItems] = useState([]);

    const navigate = useNavigate();
    const [isRoomNoInUrl, setisRoomNoInUrl] = useState(true);
    // cart add edit delte
    const [cartItems, setCartItems] = useState([]);
    const [cartPriceDetails, setCartPriceDetails] = useState([]);

    // const [originalRecommendedItems, setOriginalRecommendedItems] = useState(
    //     []
    // );
    // const [recommendedItems, setRecommendedItems] = useState([]);

    const [selectedMenuItem, setselectedMenuItem] = useState(null);
    const [isOpenAddonPopup, setIsOpenAddonPopup] = useState(false);
    const [selectedCartItemID, setselectedCartItemID] = useState(null);
    const [operationType, setOperationType] = useState({});

    // addon confirmation item
    const [addonConfirmItem, setAddonConfirmItem] = useState(null);
    const [isOpenAddonConfirm, setIsOpenAddonConfirm] = useState(false);

    // useEffect(() => {
    //     fetchRecommendedItems();
    // }, []);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
        // eslint-disable-next-line no-unused-expressions
        hotelDetails?.hotel_term?.forEach((item) => {
            if (item.type === 'ird_cart') {
                setIsChecked(item.is_checked);
            }
        });
    }, []);

    useEffect(() => {
        canvasfunc();
    }, [cartItems]);
    const canvasfunc = () => {
        const canvas = document.getElementById('signature-canvas');
        if (canvas) {
            const SignaturePad = new SignatureInstance(canvas);
            setSignaturePad(SignaturePad);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const v = localStorage.getItem('isRoomNoInUrl');
        setisRoomNoInUrl(v === '1');
        setCartItemList();
    }, []);

    // const fetchRecommendedItems = async () => {
    //     try {
    //         const hotelID = localStorage.getItem('hotel_id') || '';
    //         if (!hotelID) return;
    //         let getCartList = getLocalCartItems();
    //         const payload = {
    //             items: getCartList.map((item) => item.id) || [],
    //         };
    //         const formDataPayload = serialize(payload);
    //         const response = await axios.post(
    //             `${BaseURL}/ird/guest/get-recommended-items/${hotelID}`,
    //             formDataPayload
    //         );
    //         if (response.status === 200) {
    //             const items = response.data.data || [];
    //             const updatedItems = populateQtyInItems(items);
    //             let localCartData = getLocalCartItems();
    //             let Ids = localCartData.map((item) => item.id);
    //             let finalItems = updatedItems.filter((item) => {
    //                 if (!Ids.includes(item.id)) {
    //                     return item;
    //                 }
    //             });
    //             setOriginalRecommendedItems(updatedItems);
    //             setRecommendedItems(finalItems);
    //         }
    //     } catch (error) {}
    // };

    function setCartItemList(cartItems = null, itemId = null) {
        if (!cartItems) {
            cartItems = getLocalCartItems();
        }
        let totalTaxes = 0;
        let itemTotal = 0;
        let taxList = [];

        let fetchedcartItemList = cartItems?.map((item) => {
            let itemPrice = 0;
            let itemTax = 0;
            const tempSubItem = {
                ...item,
                id: item.id,
                itemName: item.name,
                type: item.type,
                addons: [], // contains addon-list and sub addons list,
                itemPrice,
                itemTax,
                itemCount: item.cart_item_count,
                cartItemId: item.cartItemId,
                itemActualPrice: item.price,
                cartItems: [],
            };

            // adding item price, taxes
            if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
                if (item.taxes?.length) {
                    item.taxes.sort(
                        (a, b) => a?.tax?.priority - b?.tax?.priority
                    );
                }
            }

            if (item.price > 0) {
                itemPrice += item.price;
                if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
                    let prevPriceWithTax = item.price;

                    item.taxes?.forEach((_tax) => {
                        let taxType = _tax.tax.type || 'percentage';
                        if (taxType === 'percentage') {
                            const taxPercentage = _tax.tax.percentage;
                            const taxes =
                                (taxPercentage * prevPriceWithTax) / 100;
                            prevPriceWithTax = prevPriceWithTax + taxes;
                            itemTax += taxes;
                            const taxobj = {
                                label: _tax.tax.name,
                                type: 'item_tax',
                                value: taxes * item.cart_item_count,
                                id: _tax.tax.id,
                            };
                            taxList.push(taxobj);
                        } else if (taxType === 'absolute') {
                            const taxPercentage = _tax.tax.percentage;
                            prevPriceWithTax = prevPriceWithTax + taxPercentage;
                            itemTax += taxPercentage;
                            const taxobj = {
                                label: _tax.tax.name,
                                type: 'item_tax',
                                value: taxPercentage * item.cart_item_count,
                                id: _tax.tax.id,
                            };
                            taxList.push(taxobj);
                        }
                    });
                } else {
                    item.taxes?.forEach((_tax) => {
                        let taxType = _tax.tax.type || 'percentage';
                        if (taxType === 'percentage') {
                            const taxPercentage = _tax.tax.percentage;
                            const taxes = (taxPercentage * item.price) / 100;
                            itemTax += taxes;
                            const taxobj = {
                                label: _tax.tax.name,
                                type: 'item_tax',
                                value: taxes * item.cart_item_count,
                                id: _tax.tax.id,
                            };
                            taxList.push(taxobj);
                        } else if (taxType === 'absolute') {
                            const taxPercentage = _tax.tax.percentage;
                            itemTax += taxPercentage;
                            const taxobj = {
                                label: _tax.tax.name,
                                type: 'item_tax',
                                value: taxPercentage * item.cart_item_count,
                                id: _tax.tax.id,
                            };
                            taxList.push(taxobj);
                        }
                    });
                }
            }

            // adding selected addons list (also adding price and taxes)
            const updateTempSubItem = (addOns, title = null) => {
                addOns?.forEach((addOn) => {
                    if (addOn.count > 0) {
                        const addonMetaData = {
                            ...addOn,
                            id: addOn.id,
                            title: title,
                            name: addOn.name,
                            count: addOn.count,
                            addonPrice: addOn.price,
                        };
                        tempSubItem.addons.push(addonMetaData);
                        if (addOn.price > 0) {
                            const totalAddonPrice = addOn.price * addOn.count;
                            itemPrice += totalAddonPrice;

                            if (
                                hotelDetails?.hotel_config?.taxation_type ===
                                'priority'
                            ) {
                                if (addOn.taxes?.length) {
                                    addOn.taxes.sort(
                                        (a, b) =>
                                            a?.tax?.priority - b?.tax?.priority
                                    );
                                }
                            }

                            if (
                                hotelDetails?.hotel_config?.taxation_type ===
                                'priority'
                            ) {
                                let prevAddonPrice = totalAddonPrice;
                                addOn.taxes?.forEach((_tax) => {
                                    let taxType = _tax.tax.type || 'percentage';
                                    if (taxType === 'percentage') {
                                        const taxPercentage =
                                            _tax.tax.percentage;
                                        const taxes =
                                            (taxPercentage * prevAddonPrice) /
                                            100;
                                        prevAddonPrice = prevAddonPrice + taxes;
                                        itemTax += taxes;
                                        const taxobj = {
                                            label: _tax.tax.name,
                                            type: 'item_tax',
                                            value: taxes * item.cart_item_count,
                                            id: _tax.tax.id,
                                        };
                                        taxList.push(taxobj);
                                    } else if (taxType === 'absolute') {
                                        const taxPercentage =
                                            _tax.tax.percentage;
                                        itemTax += taxPercentage;
                                        const taxobj = {
                                            label: _tax.tax.name,
                                            type: 'item_tax',
                                            value:
                                                taxPercentage *
                                                item.cart_item_count,
                                            id: _tax.tax.id,
                                        };
                                        taxList.push(taxobj);
                                    }
                                });
                            } else {
                                addOn.taxes?.forEach((_tax) => {
                                    let taxType = _tax.tax.type || 'percentage';
                                    if (taxType === 'percentage') {
                                        const taxPercentage =
                                            _tax.tax.percentage;
                                        const taxes =
                                            (taxPercentage * totalAddonPrice) /
                                            100;
                                        itemTax += taxes;
                                        const taxobj = {
                                            label: _tax.tax.name,
                                            type: 'item_tax',
                                            value: taxes * item.cart_item_count,
                                            id: _tax.tax.id,
                                        };
                                        taxList.push(taxobj);
                                    } else if (taxType === 'absolute') {
                                        const taxPercentage =
                                            _tax.tax.percentage;
                                        itemTax += taxPercentage;
                                        const taxobj = {
                                            label: _tax.tax.name,
                                            value:
                                                taxPercentage *
                                                item.cart_item_count,
                                            id: _tax.tax.id,
                                        };
                                        taxList.push(taxobj);
                                    }
                                });
                            }
                        }
                    }
                });
            };
            // adding sub-addon category and without sub-addon items in a single place
            item?.sub_addons?.forEach((subAdd) => {
                updateTempSubItem(subAdd.addons, subAdd.name);
            });
            updateTempSubItem(item.without_subaddon_addons);
            tempSubItem.itemPrice = itemPrice * item.cart_item_count; // updating item price
            itemTotal += tempSubItem.itemPrice; // updating total price
            tempSubItem.itemTax = itemTax * item.cart_item_count; // updating item tax
            totalTaxes += tempSubItem.itemTax; // updating total taxes
            return tempSubItem;
        });
        setCartItems(fetchedcartItemList);
        // this.itemTotal = itemTotal;
        // this.totalTaxes = totalTaxes;
        const finalTaxesList = taxList.reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                const indexofexistinTax = acc.findIndex((t) => t.id == x.id);
                acc[indexofexistinTax].value += current.value;
                return acc;
            }
        }, []);
        let grandTotal = itemTotal + totalTaxes;
        const arr = [
            {
                label: t['common_item_total'],
                type: 'item_total',
                value: itemTotal,
            },
            ...finalTaxesList,
            // { label: "Taxes", type: "total_tax", value: totalTaxes },
            {
                label: t['common_grand_total'],
                type: 'grand_total',
                value: grandTotal,
            },
        ];
        if (itemId) {
            let updatedItem = fetchedcartItemList.find(
                (item) => item.cartItemId === itemId
            );
            setAddonConfirmItem(updatedItem);
        }
        setCartPriceDetails(arr);
    }

    function updateMenuItemCount(itemId, count) {
        // setOutOfStockItems([]);
        const localCartItems = getLocalCartItems();
        const updatedCartItems = localCartItems.map((cartItem) => {
            if (itemId === cartItem.cartItemId) {
                cartItem.cart_item_count += count;
            }
            return cartItem;
        });
        updateAllCartItem(updatedCartItems);
        setCartItemList(updatedCartItems, itemId);
    }

    function removeItem(item) {
        const itemId = item.cartItemId;
        // this.isCartModified = true;
        if (item.itemCount === 1) {
            // Removing cart item if count is 1  //
            removeLocalCartItem(item.cartItemId);
            setCartItemList();
            handleAddonConfirmClose();
            // fetchRecommendedItems();
        } else {
            // Decrementing item count is count > 1 //
            updateMenuItemCount(itemId, -1);
        }
    }
    const onItemIncrease = (item) => {
        setIsOpenAddonConfirm(true);
        setAddonConfirmItem(item);
        const itemId = item.cartItemId;
        setselectedCartItemID(itemId);
    };
    function handleAddonConfirmClose() {
        setIsOpenAddonConfirm(false);
        setAddonConfirmItem(null);
        setselectedCartItemID(null);
    }
    function addNewAddon() {
        setIsOpenAddonPopup(true);
        let newItem = JSON.parse(JSON.stringify(addonConfirmItem));
        newItem.sub_addons?.forEach((subaddon) => {
            subaddon?.addons?.forEach((addon) => {
                addon.isChecked = false;
                addon.count = 0;
            });
        });
        setselectedMenuItem(newItem);
        setselectedCartItemID(newItem.cartItemId);
        handleAddonConfirmClose();
    }
    function handleAddonClose() {
        setIsOpenAddonPopup(false);
        // dispatch(fetchMenuItems(menuID));

        setCartItemList();

        // syncItemsAfterAddonUpdate();
    }

    function duplicateItem(item) {
        // this.isCartModified = true;
        const itemId = item.cartItemId;
        updateMenuItemCount(itemId, 1);
    }

    const getCookingInstructionLabel = () => {
        let labelText = t['checkout_ird_instructions_label'];
        // eslint-disable-next-line no-unused-expressions
        hotelDetails?.hotel_term?.forEach((element) => {
            if (element?.type === 'ird_cook_instruction') {
                if (element?.term) {
                    labelText = element?.term;
                }
            }
        });
        return <label dangerouslySetInnerHTML={{ __html: labelText }}></label>;
    };

    // Formatting cart items for checkout data
    function getApiFormattedCartItems() {
        const cartItems = getLocalCartItems();
        // const recommended_item_ids = cartItems
        //     .filter((item) => Boolean(item.is_recommended))
        //     .map((item) => item.id);
        const formattedItems = cartItems.map((cartItem) => {
            const { id, cart_item_count, sub_addons, without_subaddon_addons } =
                cartItem;

            const formattedItem = {
                menu_item_id: id,
                quantity: cart_item_count,
                addons: [],
            };

            const formatSubAddons = (addons) => {
                addons?.forEach((addon) => {
                    if (addon.count > 0) {
                        formattedItem.addons.push({
                            menu_item_addon_id: addon.id,
                            quantity: addon.count,
                        });
                    }
                });
            };

            sub_addons?.forEach((subAdd) => {
                formatSubAddons(subAdd.addons);
            });

            formatSubAddons(without_subaddon_addons);
            return formattedItem;
        });
        console.log('Formatted final api item', formattedItems);
        return {
            entries: formattedItems,
            // recommended_item_ids: recommended_item_ids,
        };
    }
    const changeDateFormat = async (date) => {
        var selecteddate = new Date(date);
        let day = '' + selecteddate.getDate();
        let month = '' + (selecteddate.getMonth() + 1);
        let year = selecteddate.getFullYear();
        if (month.length < 2) month = 0 + month;
        if (day.length < 2) day = 0 + day;
        console.log(year + '/' + month + '/' + day);
        return year + '/' + month + '/' + day;
    };

    const submitRequestHandler = async () => {
        setError('');
        setLoading(true);
        console.log(`selected Date by user ${PUDate} ${PUHour}`);
        var picupdate;
        if (hotelDetails?.hotel_config?.is_preorder_enable === 1) {
            if (preOrderToggle) {
                if (!PUDate || !PUHour) {
                    setError(t['preOrder_msg']);
                    setLoading(false);
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                    return false;
                } else {
                    const getFormatedDate = await changeDateFormat(PUDate);
                    var selectedtime = `${getFormatedDate} ${PUHour}`;
                    if (selectedtime) {
                        picupdate = convertTimeUTC(selectedtime);
                    }
                }
            }
        }

        if (!confirmNow && signaturePad._isEmpty) {
            setError(t['common_signature_empty_error']);
            canvasfunc();
            setLoading(false);
            // window.scrollTo(0, 0);
            return;
        }

        if (confirmNow && !roomNo) {
            setError(t['checkout_ird_room_no_empty_error']);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        await localStorage.setItem('room_no', roomNo);

        if (confirmNow && !screenshot) {
            setError(t['checkout_ird_error']);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        const show = shouldShowPaymentRequiredError();
        if (show) {
            setError(t['common_fill_required_fields']);
            setLoading(false);
            return;
        }
        try {
            const dataURL = signaturePad.toDataURL();
            let blob = dataURItoBlob(dataURL);
            let signatureImage = new File([blob], 'signature.png', {
                type: 'image/jpeg',
            });
            const otherPayload = getApiFormattedCartItems();
            const orderData = {
                // entries: getApiFormattedCartItems(),
                guests,
                instructions,
                paymentStatus: confirmNow ? 'done' : 'offline',
                preorder_on: picupdate,
                ...otherPayload,
            };
            if (
                hotelDetails?.hotel_payment_modes?.length &&
                (singleSelectPaymentOption || multiSelectPaymentOption.length)
            ) {
                if (singleSelectPaymentOption) {
                    orderData['payment_mode_id'] = [singleSelectPaymentOption];
                } else {
                    orderData['payment_mode_id'] = multiSelectPaymentOption;
                }
            }
            await submitRequest(
                confirmNow ? screenshot[0] : signatureImage,
                orderData
            );
            ReactGA.set({ foodOrderPlaced: true });
            ReactGA.event({
                category: 'Food',
                action: 'Food Order Placed',
            });
            makeLocalCartItemsEmpty();
            // const sessionID = LS.initiate();
            // LS.clear(sessionID);
            navigate('/food-thank-you');
            setLoading(false);
        } catch (error) {
            const { response = {} } = error;
            if (response.status === 400) {
                let itemsIdList = response.data?.data?.unavialable_items || [];
                let addonsIdList =
                    response?.data?.data?.unavialable_addons || [];
                if (itemsIdList.length || addonsIdList.length) {
                    itemsIdList = itemsIdList.map((id) => Number(id));
                    addonsIdList = addonsIdList.map((id) => Number(id));
                    setOutOfStockItems([...itemsIdList, ...addonsIdList]);
                    window.scrollTo(0, 0);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const shouldShowPaymentRequiredError = () => {
        const hasEnabledPG =
            hotelDetails?.hotel_payment_modes?.filter((item) =>
                Boolean(item.enabled)
            )?.length > 0;
        const isPaymentRequired =
            hotelDetails?.hotel_payment_config?.is_required;
        const isPaymentEnabled = hotelDetails?.hotel_payment_config?.enabled;
        if (isPaymentEnabled !== 1) {
            return false;
        }
        if (multiSelectPaymentOption.length > 0 || singleSelectPaymentOption) {
            return false;
        }
        if (isPaymentRequired && hasEnabledPG) return true;
        return false;
    };

    const onRoonNoBlur = () => {
        setLoading(true);
        getVerifyRoomNo(roomNo)
            .then((res) => {
                const r = res?.data;
                setError('');
            })
            .catch((err) => {
                const e = err?.response?.status;
                if (e === 404) {
                    setRoomNo(null);
                    window.scrollTo(0, 0);
                    let msg =
                        hotelDetails.id === 211
                            ? t['villa_table_no']
                            : t['room_villa_no'];
                    setError(`Please enter a valid ${msg}`);
                }
            })
            .finally(() => setLoading(false));
    };
    const checkIfOutOfStock = (item) => {
        let isOutofStock = false;

        // check if item of addon exist
        if (outOfStockItems.includes(item.id)) {
            isOutofStock = true;
        } else if (item.addons && item.addons.length) {
            outOfStockItems?.forEach((addonID) => {
                item?.addons?.forEach((item) => {
                    if (item.id === addonID) {
                        isOutofStock = true;
                    }
                });
            });
        }
        return isOutofStock;
    };

    // const handleRecommendedItems = () => {
    //     let localCartData = getLocalCartItems();
    //     let Ids = localCartData.map((item) => item.id);
    //     let finalItems = originalRecommendedItems.filter((item) => {
    //         if (!Ids.includes(item.id)) {
    //             return item;
    //         }
    //     });
    //     setRecommendedItems(finalItems);
    // };

    const addItem = (e, item) => {
        const updatedItem = generateCartItem(item);
        let getCartList = getLocalCartItems();
        const data = [...getCartList, updatedItem];
        localStorage.setItem('cartItems', JSON.stringify(data));
        setCartItemList();
        // handleRecommendedItems();
    };

    const handleMultiSelect = (optionId, isChecked) => {
        if (isChecked) {
            setMultiSelectPaymentOption((prevOptions) => [
                ...prevOptions,
                optionId,
            ]);
        } else {
            setMultiSelectPaymentOption((prevOptions) =>
                prevOptions.filter((id) => id !== optionId)
            );
        }
    };

    return (
        <>
            {loading && <Loading />}
            <Header showBack backBtnHandler={() => navigate(-1)} />
            {!cartItems.length ? (
                <div className={styles.emptyCartScreen}>
                    <p>{t['checkout_ird_food_card_empty_error']}</p>

                    <Button type='primary' onClick={() => navigate(-1)} className='bg-theme'>
                        {t['checkout_ird_empty_btn']}
                    </Button>
                </div>
            ) : (
                <section style={{ width: '100%', backgroundColor: '#f3f3f3' }}>
                    {hotelDetails?.hotel_term?.map((item) => {
                        if (item?.type === 'ird_cart_top') {
                            return (
                                <p
                                    className='ird-checkout-warning'
                                    dangerouslySetInnerHTML={{
                                        __html: `&#x26A0; ${item.term}`,
                                    }}
                                ></p>
                            );
                        }
                    })}
                    <div className='flex flex-col gap-12 p-2 pb-16'>
                        <div className='flex flex-col gap-2'>
                            {outOfStockItems.length > 0 && (
                                <div className='out-of-service'>
                                    <div className='icon'>
                                        <i className='fa fa-info-circle'></i>
                                    </div>
                                    <div className='text'>
                                        t{'out_of_stock_error'}
                                    </div>
                                </div>
                            )}
                            {cartItems.map((item, position) => (
                                <div className='item-food-card'>
                                    <div
                                        className={
                                            checkIfOutOfStock(item)
                                                ? 'item-details-wrapper out-of-stock'
                                                : 'item-details-wrapper border border-gray-300'
                                        }
                                    >
                                        {(item.type === 'veg' ||
                                            item.type === 'non_veg') && (
                                            <div
                                                className={
                                                    'icon-img ' +
                                                    (item.type === 'non_veg'
                                                        ? 'non-veg'
                                                        : 'veg')
                                                }
                                            ></div>
                                        )}
                                        <div className='item-title-wrapper'>
                                            <div className='item-title-price-wrapper'>
                                                <div className='item-title'>
                                                    {item.itemName}
                                                </div>
                                                <div className='item-price'>
                                                    {item.itemPrice > 0
                                                        ? ` ${currentCurrency} ${renderPrice(
                                                              item.itemPrice,
                                                              hotelDetails
                                                          )}`
                                                        : `  `}
                                                </div>
                                                {item.addons?.length !== 0 ? (
                                                    <ul className='selectedAddonList'>
                                                        {item?.addons?.map(
                                                            (add) => {
                                                                return (
                                                                    <li>
                                                                        <span>
                                                                            {
                                                                                add.title
                                                                            }{' '}
                                                                            {
                                                                                ' : '
                                                                            }
                                                                        </span>
                                                                        <span className='addon-name'>
                                                                            {
                                                                                add.name
                                                                            }{' '}
                                                                            {add.count >
                                                                            1
                                                                                ? ' - ' +
                                                                                  add.count
                                                                                : ''}
                                                                        </span>
                                                                        {add.addonPrice >
                                                                        0 ? (
                                                                            <span>
                                                                                {' '}
                                                                                {
                                                                                    ' x '
                                                                                }
                                                                                {
                                                                                    currentCurrency
                                                                                }{' '}
                                                                                {renderPrice(
                                                                                    add.addonPrice,
                                                                                    hotelDetails
                                                                                )}
                                                                            </span>
                                                                        ) : null}
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </div>
                                            <div className='item-actions'>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() =>
                                                            removeItem(item)
                                                        }
                                                        icon={<MinusOutlined />}
                                                        className='bg-theme'
                                                    />
                                                    <Button
                                                        icon={String(
                                                            item.itemCount
                                                        )}
                                                    />

                                                    <Button
                                                        onClick={() =>
                                                            onItemIncrease(item)
                                                        }
                                                        className='bg-theme'
                                                        icon={<PlusOutlined />}
                                                    />
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* {recommendedItems.length > 0 && (
                            <>
                                <div className='recommend-title'>
                                    <span>{t['complete_meal_msg']}</span>
                                </div>
                                <div className='recommended-items-container'>
                                    {recommendedItems.map(
                                        (item) =>
                                            Boolean(item.enabled) && (
                                                <div className='item-food-card'>
                                                    <div
                                                        className={`item-details-wrapper small_thumbnail`}
                                                    >
                                                        <div className='item-thumbnail'>
                                                            {item.thumbnail ? (
                                                                <img
                                                                    src={
                                                                        IRDITEM_IMAGE_ROOT_URL +
                                                                        item.thumbnail
                                                                    }
                                                                    alt={
                                                                        item.name
                                                                    }
                                                                    className='item-image'
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        NoItemImg
                                                                    }
                                                                    alt={
                                                                        item.name
                                                                    }
                                                                    className='item-image'
                                                                />
                                                            )}
                                                            {(item.type ===
                                                                'veg' ||
                                                                item.type ===
                                                                    'non_veg') && (
                                                                <div
                                                                    className={
                                                                        'icon-img ' +
                                                                        (item.type ===
                                                                        'non_veg'
                                                                            ? 'non-veg'
                                                                            : 'veg')
                                                                    }
                                                                ></div>
                                                            )}
                                                        </div>

                                                        <div className='item-title-wrapper'>
                                                            <div className='item-title-price-wrapper'>
                                                                <div className='item-title'>
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            <div className='item-actions'>
                                                                <div className='item-price'>
                                                                    {item.price ? (
                                                                        <span>
                                                                            {
                                                                                currentCurrency
                                                                            }{' '}
                                                                            {renderPrice(
                                                                                item.price,
                                                                                hotelDetails
                                                                            )}
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                                <button
                                                                    className='btn-add'
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        addItem(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className='fas fa-plus'
                                                                        aria-hidden='true'
                                                                    ></i>{' '}
                                                                    {t['Add']}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                            </>
                        )} */}
                            <div
                                className={
                                    'flex  justify-center items-start flex-col gap-2'
                                }
                            >
                                {getCookingInstructionLabel()}
                                <TextArea
                                    value={instructions}
                                    onChange={(e) =>
                                        updateInstructions(e.target.value)
                                    }
                                    placeholder={
                                        hotelDetails.id === 63 ||
                                        hotelDetails.id === 605
                                            ? t['no_veggie_msg']
                                            : ''
                                    }
                                    rows={3}
                                />
                            </div>
                            {cartPriceDetails.length > 0 ? (
                                <div className='priceDetails-wraper shadow-sm border-1'>
                                    {cartPriceDetails?.map((data, index) => {
                                        if (data.value || data.value == 0) {
                                            return (
                                                <div
                                                    className={`details-list ${data.type}`}
                                                    key={`${index}-${data.label}`}
                                                >
                                                    <span className='type_label'>
                                                        {data?.label}
                                                    </span>
                                                    <span className='value_label'>
                                                        {currentCurrency}{' '}
                                                        {renderPrice(
                                                            data.value,
                                                            hotelDetails
                                                        )}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : null}
                        </div>

                        <div className='flex flex-col gap-4'>
                            {hotelDetails.id === 366 && (
                                <div className='inforMsg'>
                                    <i className='fa fa-info-circle'></i>{' '}
                                    <span>
                                        Eligible discount if any will reflect on
                                        the invoice presented to you during food
                                        delivery.
                                    </span>
                                </div>
                            )}
                            {hotelDetails.id === 472 && (
                                <div className='inforMsg'>
                                    <i className='fa fa-info-circle'></i>{' '}
                                    <span>
                                        Deck chair number can be found behind
                                        the chair.
                                    </span>
                                </div>
                            )}

                            {hotelDetails?.hotel_payment_config?.enabled &&
                            hotelDetails?.hotel_payment_modes?.length > 0 ? (
                                <div className='deliveryType_wrapper'>
                                    <label
                                        style={{
                                            marginBottom: '1rem',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {
                                            hotelDetails?.hotel_payment_config
                                                ?.heading
                                        }
                                    </label>
                                    {hotelDetails.hotel_payment_config
                                        ?.multi_select
                                        ? hotelDetails.hotel_payment_modes.map(
                                              (option) =>
                                                  option.enabled === 1 ? (
                                                      <div
                                                          className='option_type gap-2'
                                                          key={option.id}
                                                      >
                                                          {/* <Checkbox
                                                              name='deliveryType'
                                                              onChange={(e) =>
                                                                  handleMultiSelect(
                                                                      option.id,
                                                                      e.target
                                                                          .checked
                                                                  )
                                                              }
                                                              value={option.id}
                                                              id={option.id}
                                                          >
                                                              {option.name}
                                                          </Checkbox>
                                                          <br>
                                                          </br> */}
                                                          <input
                                                              className={
                                                                  styles.timeInputCheckbox
                                                              }
                                                              type='checkbox'
                                                              id={option.id}
                                                              name='deliveryType'
                                                              value={option.id}
                                                              onChange={(e) =>
                                                                  handleMultiSelect(
                                                                      option.id,
                                                                      e.target
                                                                          .checked
                                                                  )
                                                              }
                                                          />{' '}
                                                          <label
                                                              className='label'
                                                              htmlFor={
                                                                  option.id
                                                              }
                                                          >
                                                              {option.name}
                                                          </label>
                                                      </div>
                                                  ) : null
                                          )
                                        : hotelDetails.hotel_payment_modes.map(
                                              (option) =>
                                                  option.enabled === 1 ? (
                                                      <div
                                                          className='option_type gap-2'
                                                          key={option.id}
                                                      >
                                                          <input
                                                              className={
                                                                  styles.timeInputCheckbox
                                                              }
                                                              type='radio'
                                                              id={option.id}
                                                              name='deliveryType'
                                                              value={option.id}
                                                              onChange={(e) =>
                                                                  setSingleSelectPaymentOption(
                                                                      option.id
                                                                  )
                                                              }
                                                              disabled={
                                                                  option.enabled ===
                                                                  0
                                                              }
                                                          />
                                                          <label
                                                              className='option_name'
                                                              htmlFor={
                                                                  option.id
                                                              }
                                                          >
                                                              {option.name}
                                                          </label>
                                                      </div>
                                                  ) : null
                                          )}
                                </div>
                            ) : null}

                            <div
                                className={
                                    'flex  justify-between items-center gap-2'
                                }
                            >
                                <label className={'flex-1'}>
                                    {hotelDetails.id === 211
                                        ? t['villa_table_no']
                                        : hotelDetails.id === 472
                                        ? t['deck_chair_no']
                                        : hotelDetails.id === 343
                                        ? 'Room/Table no.'
                                        : hotelDetails.id === 659
                                        ? t['table_number']
                                        : t['room_villa_no']}
                                </label>
                                {!isRoomNoInUrl ? (
                                    <input
                                        type='text'
                                        value={roomNo}
                                        onChange={(e) => {
                                            setRoomNo(e?.target?.value);
                                        }}
                                        onBlur={(e) => onRoonNoBlur()}
                                        placeholder={
                                            hotelDetails.id === 211
                                                ? t['villa_table_no']
                                                : hotelDetails.id === 472
                                                ? t['deck_chair_no']
                                                : hotelDetails.id === 343
                                                ? 'Room/Table no.'
                                                : hotelDetails.id === 659
                                                ? t['table_number']
                                                : t['room_villa_no']
                                        }
                                        className='roomNumberInput flex-1'
                                    />
                                ) : (
                                    <label>{roomNumber}</label>
                                )}
                            </div>

                            <div className={styles.guestsCounter}>
                                <label>
                                    {hotelDetails.id === 418
                                        ? 'Number of Guests  عدد الضيوف'
                                        : t['checkout_number_of_guests']}
                                </label>
                                {/* <Counter
                                    count={guests}
                                    increment={() => updateGuests(guests + 1)}
                                    decrement={
                                        guests === 0
                                            ? null
                                            : () => updateGuests(guests - 1)
                                    }
                                /> */}
                                <ButtonGroup>
                                    <Button
                                        onClick={
                                            guests === 0
                                                ? null
                                                : () => updateGuests(guests - 1)
                                        }
                                        icon={<MinusOutlined />}
                                        className='bg-theme'
                                    />
                                    <Button icon={String(guests)} />

                                    <Button
                                        onClick={() => updateGuests(guests + 1)}
                                        icon={<PlusOutlined />}
                                        className='bg-theme'
                                    />
                                </ButtonGroup>
                            </div>
                            {hotelDetails?.hotel_config?.is_preorder_enable ===
                            1 ? (
                                <div
                                    className={
                                        'preOrder-wrapper flex gap-3 flex-col' +
                                        (preOrderToggle ? ' on' : '')
                                    }
                                >
                                    <div className={'flex gap-2'}>
                                        <Switch
                                            value={preOrderToggle}
                                            onChange={() =>
                                                setPreOrderToggle(
                                                    !preOrderToggle
                                                )
                                            }
                                        />
                                        <label>{t['preorder_title']}</label>
                                    </div>
                                    {preOrderToggle ? (
                                        <>
                                            <div
                                                className={
                                                    styles.timeInputWrapper
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.selectTime
                                                    }
                                                >
                                                    {t['common_select_date']}
                                                </p>
                                                <DatePickerComponent
                                                    placeholder={
                                                        t['select_date']
                                                    }
                                                    value={PUDate}
                                                    config={dateConfig}
                                                    confirmText={
                                                        t['common_done_btn']
                                                    }
                                                    cancelText={
                                                        t['common_cancel']
                                                    }
                                                    saveTime={(time) => {
                                                        setPUHour(null);
                                                        setPUDate(time);
                                                    }}
                                                    cType='date'
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.timeInputWrapper
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.selectTime
                                                    }
                                                >
                                                    {t['common_select_time']}
                                                </p>
                                                <DatePickerComponent
                                                    placeholder={
                                                        t['select_time']
                                                    }
                                                    value={PUHour}
                                                    config={timeConfig}
                                                    confirmText={
                                                        t['common_done_btn']
                                                    }
                                                    cancelText={
                                                        t['common_cancel']
                                                    }
                                                    errorText={
                                                        t[
                                                            'checkout_ird_time_error_msg'
                                                        ]
                                                    }
                                                    saveTime={(time) =>
                                                        setPUHour(time)
                                                    }
                                                    cType='hour'
                                                    minHour={4}
                                                    maxHour={24}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}
                            <>
                                {hotelDetails.paytm_link && (
                                    <div
                                        className={
                                            confirmationStyles.switchContainer
                                        }
                                    >
                                        <p>{t['checkout_ird_settle_later']}</p>
                                        <Switch
                                            value={confirmNow}
                                            onChange={() =>
                                                setConfirmationMethod(
                                                    !confirmNow
                                                )
                                            }
                                        />
                                        <p>{t['checkout_ird_settle_now']}</p>
                                    </div>
                                )}
                                {confirmNow ? (
                                    <div className={styles.payNowWindow}>
                                        <div className={styles.stepOne}>
                                            <p className={styles.step}>
                                                {t['checkout_step_one']}
                                            </p>
                                            <p>
                                                {t['checkout_step_one_content']}
                                            </p>
                                            <a
                                                href={hotelDetails.paytm_link}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className={styles.noLink}
                                            >
                                                <Button type='proceed'
                                                className='bg-theme'
                                                >
                                                    {
                                                        t[
                                                            'checkout_step_one_button"'
                                                        ]
                                                    }
                                                </Button>
                                            </a>
                                        </div>
                                        <div
                                            className={styles.uploadFileWrapper}
                                        >
                                            <p className={styles.step}>
                                                {t['checkout_step_two']}
                                            </p>
                                            <p>
                                                {t['checkout_step_two_content']}
                                            </p>
                                            <label
                                                htmlFor='file-upload'
                                                className={styles.uploadFile}
                                            >
                                                <img
                                                    src={upload}
                                                    alt='Upload File Icon'
                                                />
                                                <span>
                                                    {
                                                        t[
                                                            'checkout_step_two_button'
                                                        ]
                                                    }
                                                </span>
                                            </label>

                                            <input
                                                id='file-upload'
                                                type='file'
                                                label={
                                                    t[
                                                        'checkout_step_two_button'
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    setScreenshot(
                                                        e.target.files
                                                    )
                                                }
                                            />
                                            <p
                                                className={
                                                    styles.uploadedFileName
                                                }
                                            >
                                                {screenshot &&
                                                    screenshot[0].name}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                !screenshot
                                                    ? styles.stepThreeDisabled
                                                    : styles.stepThree
                                            }
                                        >
                                            <p className={styles.step}>
                                                {t['checkout_step_three']}
                                            </p>
                                            <p>
                                                {
                                                    t[
                                                        'checkout_step_three_content'
                                                    ]
                                                }
                                            </p>
                                            <Button
                                                label={
                                                    t[
                                                        'checkout_step_three_button'
                                                    ]
                                                }
                                                type='proceed'
                                                className='bg-theme'
                                                onClick={
                                                    screenshot
                                                        ? () =>
                                                              submitRequestHandler()
                                                        : null
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Signature
                                            signaturePad={signaturePad}
                                        />
                                        <RenderConfirmationCheckbox
                                            hotelDetails={hotelDetails}
                                            setIsChecked={setIsChecked}
                                            isChecked={isChecked}
                                        />
                                        <div className='flex justify-center items-center'>
                                            <Button
                                                disabled={
                                                    !isChecked || guests === 0
                                                }
                                                className={
                                                    !isChecked ||
                                                    !roomNo ||
                                                    guests === 0
                                                        ? confirmationStyles.disableBtn
                                                        : 'bg-theme'
                                                }
                                                onClick={() =>
                                                    submitRequestHandler()
                                                }
                                                type='primary'
                                                size='large'
                                            >
                                                {t['common_proceed_btn']}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {error && (
                                    <p className={styles.checkoutFoodError}>
                                        {error}
                                    </p>
                                )}
                            </>
                        </div>
                    </div>
                </section>
            )}

            {isOpenAddonPopup && selectedMenuItem && (
                <AddonComponent
                    selectedCartItemID={selectedCartItemID}
                    menuItem={selectedMenuItem}
                    open={isOpenAddonPopup}
                    close={handleAddonClose}
                    updateOperationType={(type) => setOperationType(type)}
                />
            )}
            {isOpenAddonConfirm && addonConfirmItem && (
                <CheckoutAddonRepeatConfirm
                    menuItem={addonConfirmItem}
                    item={addonConfirmItem}
                    open={isOpenAddonConfirm}
                    duplicateItem={duplicateItem}
                    removeItem={removeItem}
                    close={handleAddonConfirmClose}
                    addNewAddon={addNewAddon}
                    //   updateOperationType={getAddonOperationType}
                />
            )}
        </>
    );
};

export default DataProvider(Checkout);

const RenderConfirmationCheckbox = (props) => {
    const { setIsChecked, isChecked, hotelDetails } = props;
    let checkboxLabelNode = hotelDetails?.hotel_term.find(
        (item) => item.type === 'ird_cart'
    );
    const checkboxLabel = checkboxLabelNode['term'] || 'test';
    console.log(checkboxLabel)
    return (
        <div className={''}>
            <Checkbox
                className={confirmationStyles.InputCheckBox}
                onChange={(e) => setIsChecked(e.target.checked)}
                checked={isChecked}
                id='term-check'
            >
            </Checkbox>
            <label
                htmlFor='term-check'
                className={confirmationStyles.labelSize}
                dangerouslySetInnerHTML={{ __html: checkboxLabel }}
            ></label>
        </div>
    );
};
