import React, { createContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Layout from './layout/layout';
import { Loading } from './components/components';
import {
    Connect,
    Landing,
    Laundry,
    LaundryThankYou,
    Login,
    LanguageSelector,
    OrderFood,
    OrderFoodCategoriesList,
    FoodThankYou,
    PlaceRequest,
    Reuse,
    Shield,
    Spa,
    SpaThankYou,
    TvGuide,
    Welcome,
    CheckoutFood,
    CheckoutLaundry,
    CheckoutSpa,
    CheckoutPlaceRequest,
    RequestThankYou,
    SpaCategoriesList,
    StaticService,
    Bookings,
    BookingCheckout,
    Feed,
} from './views';
import ContentCard from './views/orderFood/contentCard';
import ThankYou from './views/bookings/ThankYou';
import BookingHistory from './views/bookings/BookingHistory';
import { useHotel } from './context/HotelContext';
import LoginScreen from './views/login/LoginScreen';
import { Result } from 'antd';
export const HotelContext = createContext({});
const App = () => {
    const { hotelDetails, loading } = useHotel();
    const navigate = useNavigate();

    useEffect(() => {
        let hotelID = localStorage.getItem('hotel_id');
        if (!hotelID) {
            navigate('/page-not-found', { replace: true });
        }
    }, []);

    if (loading) {
        return <Loading />;
    }
    const isAvailable = (service) => {
        return hotelDetails?.hotel_services?.find(
            (item) => item.type === service
        );
    };
    return (
        <Routes>
            {isAvailable('laundry') && (
                <Route
                    path='laundry'
                    element={
                        <Layout>
                            <Laundry />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('ird') && (
                <Route
                    path='ird'
                    element={
                        <Layout>
                            <OrderFoodCategoriesList />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('spa') && (
                <Route
                    path='spa'
                    element={
                        <Layout>
                            <SpaCategoriesList />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('ird') && (
                <Route
                    path='checkout-food'
                    element={
                        <Layout>
                            <CheckoutFood />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('laundry') && (
                <Route
                    path='checkout-laundry'
                    element={
                        <Layout>
                            <CheckoutLaundry />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('spa') && (
                <Route
                    path='checkout-spa'
                    element={
                        <Layout>
                            <CheckoutSpa />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('laundry') && (
                <Route
                    path='laundry-thank-you'
                    element={
                        <Layout>
                            <LaundryThankYou />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('spa') && (
                <Route
                    path='spa-thank-you'
                    element={
                        <Layout>
                            <SpaThankYou />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('ird') && (
                <Route
                    path='food-thank-you'
                    element={
                        <Layout>
                            <FoodThankYou />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('connect') && (
                <Route
                    path='connect'
                    element={
                        <Layout>
                            <Connect />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('conserve') && (
                <Route
                    path='conserve'
                    element={
                        <Layout>
                            <Reuse />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('project_shield') && (
                <Route
                    path='project_shield'
                    element={
                        <Layout>
                            <Shield />
                        </Layout>
                    }
                ></Route>
            )}
            {isAvailable('tv_guide') && (
                <Route
                    path='tv_guide'
                    element={
                        <Layout>
                            <TvGuide />
                        </Layout>
                    }
                ></Route>
            )}
            <Route
                path='feed'
                element={
                    <Layout>
                        <Feed />
                    </Layout>
                }
            ></Route>
            <Route
                path='login'
                element={
                    <Layout>
                        <LoginScreen />
                    </Layout>
                }
            ></Route>
            <Route
                path='thank-you'
                element={
                    <Layout>
                        <ThankYou />
                    </Layout>
                }
            ></Route>
            <Route
                path='select-language'
                element={
                    <Layout>
                        <LanguageSelector />
                    </Layout>
                }
            ></Route>
            <Route
                path='order/:menuID'
                element={
                    <Layout>
                        <OrderFood />
                    </Layout>
                }
            ></Route>
            <Route
                path='content'
                element={
                    <Layout>
                        <ContentCard />
                    </Layout>
                }
            ></Route>
            <Route
                path='service_content'
                element={
                    <Layout>
                        <StaticService />
                    </Layout>
                }
            ></Route>
            <Route
                path='orderspa/:spaID'
                element={
                    <Layout>
                        <Spa />
                    </Layout>
                }
            ></Route>
            <Route
                path='place-request'
                element={
                    <Layout>
                        <PlaceRequest />
                    </Layout>
                }
            ></Route>
            <Route
                path='checkout-place-request'
                element={
                    <Layout>
                        <CheckoutPlaceRequest />
                    </Layout>
                }
            ></Route>
            <Route
                path='request-thank-you'
                element={
                    <Layout>
                        <RequestThankYou />
                    </Layout>
                }
            ></Route>
            <Route
                path='booking'
                exact
                element={
                    <Layout>
                        <Bookings />
                    </Layout>
                }
            ></Route>
            <Route
                path='confirmbooking'
                exact
                element={
                    <Layout>
                        <BookingCheckout />
                    </Layout>
                }
            ></Route>
            <Route
                path='booking-history'
                exact
                element={
                    <Layout>
                        <BookingHistory />
                    </Layout>
                }
            ></Route>
            <Route
                path='welcome'
                element={
                    <Layout>
                        <Welcome />
                    </Layout>
                }
            ></Route>
            <Route
                path='about'
                element={
                    <Layout>
                        <Welcome />
                    </Layout>
                }
            ></Route>
            <Route path='/' element={<Landing />}></Route>
            <Route path='page-not-found' element={<PageNotFound />}></Route>

            <Route path='' element={<Landing />}></Route>
        </Routes>
    );
};

export default App;

const PageNotFound = () => {
    return (
        <div className='flex flex-col justify-center items-center flex-1 min-h-screen'>
            <Result
                status='500'
                title='Page Not Found'
                subTitle='Something went wrong! Please scan the QR again or open the link again'
                // extra={
                //     <Button
                //         onClick={() => navigate('/', { replace: true })}
                //     >
                //         Back to Home
                //     </Button>
                // }
            />
        </div>
    );
};
