/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styles from './orderFood.module.css';
import { useNavigate } from 'react-router-dom';
import { Card, Header } from '../../components/components';
import { getPrivateResource } from '../../services/api';

const ContentCard = ({ location }) => {
    const navigate = useNavigate();
    const [content, setContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getContent = async (id) => {
        const hotelID = localStorage.getItem('hotel_id') || false;
        setIsLoading(() => true);
        const res = await getPrivateResource(
            `/ird/guest/static-contents/${hotelID}/${id}`,
            true
        );
        setIsLoading(() => false);
        const data = res?.data?.data;
        if (data) {
            setContent(data?.child_hotel_static_content);
        }
    };

    useEffect(() => {
        getContent(location?.data);
    }, [location]);
    const locationBack = () => {
        navigate('/ird');
    };

    return (
        <>
            <Header showBack />
            <div className={styles.irdtiles}>
            

                {content?.map((c) => {
                    switch (c?.type) {
                        case 'html':
                            return (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: c?.content,
                                    }}
                                ></div>
                            );
                        case 'image':
                            return <Card cover={c?.content} />;
                        case 'link':
                            return (
                                <Card
                                    cover={
                                        'https://master-vserve.s3.ap-south-1.amazonaws.com/hotel-static-content/' +
                                        c?.banner
                                    }
                                    onclick={() => {
                                        navigate(c?.content);
                                    }}
                                />
                            );
                        default:
                            return <></>;
                    }
                })}
            </div>
        </>
    );
};

export default ContentCard;
