import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHotel } from '../../context/HotelContext';
import { Button, Input, Select } from 'antd';
import { Header, Loading, TextInput } from '../../components/components';
import { EMAIL_REGEXP, countryList } from '../../services/Typecode';
import ReactGA from 'react-ga4';
import { userData, login, IMAGE_ROOT_URL } from '../../services/api';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isEmptyError, setIsEmptyError] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobile_country_code, setmobile_country_code] = useState('91');
    const [fullName, setFullName] = useState('');
    const { hotelDetails } = useHotel();
    const hotelId = localStorage.getItem('hotel_id') || false;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const forwardUrl = searchParams.get('request') || '';
    console.log(forwardUrl);
    const { t } = useLanguage();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, []);

    useEffect(() => {
        if (
            hotelDetails?.hotel_config &&
            hotelDetails?.hotel_config?.required_guest_details &&
            hotelDetails?.hotel_config?.required_guest_details.length === 0
        ) {
            
            handleSubmit();
        }
    }, [hotelDetails]);

    const handleSubmit = async (e) => {
        if(e) e.preventDefault();
        // if hotel_config is set, check individual validations for required fields
        if (
            hotelDetails?.hotel_config &&
            hotelDetails?.hotel_config?.required_guest_details &&
            hotelDetails?.hotel_config?.required_guest_details.length !== 0
        ) {
            if (hotelDetails?.hotel_config) {
                if (
                    hotelDetails?.hotel_config?.required_guest_details.includes(
                        'email'
                    )
                ) {
                    if (email === undefined || email === '') {
                        setIsEmptyError('Please enter your details.');
                        return false;
                    }
                }
                if (
                    hotelDetails?.hotel_config?.required_guest_details.includes(
                        'name'
                    )
                ) {
                    if (fullName === undefined || fullName === '') {
                        setIsEmptyError('Please enter your details.');
                        return false;
                    }
                }
                if (
                    hotelDetails?.hotel_config?.required_guest_details.includes(
                        'last_name'
                    )
                ) {
                    if (lastName === undefined || lastName === '') {
                        setIsEmptyError('Please enter your details.');
                        return false;
                    }
                }
                if (
                    hotelDetails?.hotel_config?.required_guest_details.includes(
                        'first_name'
                    )
                ) {
                    if (firstName === undefined || firstName === '') {
                        setIsEmptyError('Please enter your details.');
                        return false;
                    }
                }
                if (
                    hotelDetails?.hotel_config?.required_guest_details.includes(
                        'mobile'
                    )
                ) {
                    if (mobile === undefined || mobile === '') {
                        setIsEmptyError('Please enter your details.');
                        return false;
                    }
                }
            }
        }

        ReactGA.set({ email: email });
        ReactGA.set({ name: fullName });
        ReactGA.set({ firstName: firstName });
        ReactGA.set({ lastname: lastName });
        ReactGA.set({ mobile: mobile });
        ReactGA.set({ mobile_country_code: mobile_country_code });

        setLoading(true);
        let dataObj = {
            hotelId,
        };

        if (
            hotelDetails?.hotel_config &&
            hotelDetails?.hotel_config?.required_guest_details &&
            hotelDetails?.hotel_config?.required_guest_details.length === 0
        ) {
            dataObj['name'] = 'guestUser';
        }
        if (hotelDetails?.hotel_config) {
            if (
                hotelDetails?.hotel_config?.required_guest_details.includes(
                    'email'
                )
            ) {
                dataObj['email'] = email;
            }
            if (
                hotelDetails?.hotel_config?.required_guest_details.includes(
                    'name'
                )
            ) {
                dataObj['name'] = fullName;
            }
            if (
                hotelDetails?.hotel_config?.required_guest_details.includes(
                    'last_name'
                )
            ) {
                dataObj['last_name'] = lastName;
            }
            if (
                hotelDetails?.hotel_config?.required_guest_details.includes(
                    'first_name'
                )
            ) {
                dataObj['first_name'] = firstName;
            }
            if (
                hotelDetails?.hotel_config?.required_guest_details.includes(
                    'mobile'
                )
            ) {
                dataObj['mobile'] = mobile;
                dataObj['mobile_country_code'] = mobile_country_code;
            }
        }
        try {
            const response = await login(dataObj);
            if (response.status === 201) {
                const data = response.data?.data || {};
                localStorage.setItem('hotel_token', data.access_token);
                const responseProfile = await userData('/user/profile');
                if (responseProfile.status === 200) {
                    const userData = responseProfile.data?.data || {};
                    localStorage.setItem('__uid__', userData.user?.id);
                    ReactGA.event({
                        category: 'Login',
                        action: 'User Login Success',
                        hotelId,
                        email,
                        lastName,
                    });
                    setIsEmptyError('');
                    navigate(`/${forwardUrl}`, { replace: true });
                }
            }
        } catch (e) {
            console.log(e);
            window.alert(t['common_error']);
            window.location.reload();
        } finally {
            setLoading(false);
        }
    };
    const handleMobileChange = (value = '') => {
        let number = '';
        if (value) {
            let numberPattern = /\d+/g;
            number = value.match(numberPattern)?.join('') || '';
        }
        setMobile(number);
    };
    return (
        <>
            <Header showBack />
            <div
                className={'flex flex-col gap-6 p-2 pb-16 justify-center'}
                style={{ minHeight: '90vh', backgroundColor: '#f8f7f4' }}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <form
                            onSubmit={handleSubmit}
                            className='flex flex-col gap-2'
                        >
                            <div className='flex justify-center items-center mb-4'>
                                <img
                                    onClick={() => navigate('/')}
                                    src={IMAGE_ROOT_URL + hotelDetails?.logo}
                                    alt={`${hotelDetails?.name} logo`}
                                    className={
                                        'object-contain max-w-28 max-h-28 mix-blend-multiply'
                                    }
                                />
                            </div>
                            {hotelDetails?.hotel_config?.required_guest_details.includes(
                                'email'
                            ) && (
                                <div className=' flex flex-row w-full'>
                                    <Input
                                        placeholder={
                                            t['login_email_placedholder']
                                        }
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required
                                        className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$'
                                    />
                                </div>
                            )}

                            {hotelDetails?.hotel_config?.required_guest_details.includes(
                                'last_name'
                            ) && (
                                <div className=' flex flex-row w-full'>
                                    <Input
                                        type='text'
                                        name='lastName'
                                        placeholder={t['last_name']}
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                        required
                                    />
                                </div>
                            )}

                            {hotelDetails?.hotel_config?.required_guest_details.includes(
                                'first_name'
                            ) && (
                                <div className=' flex flex-row w-full'>
                                    <Input
                                        type='text'
                                        name='firstName'
                                        placeholder={t['first_name']}
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                        required
                                    />
                                </div>
                            )}

                            {hotelDetails?.hotel_config?.required_guest_details.includes(
                                'mobile'
                            ) && (
                                <>
                                    <div className='flex flex-row gap-4 justify-between items-center'>
                                        <Select
                                            name='mobile_country_code'
                                            id='mobile_country_code'
                                            onChange={(value) =>
                                                setmobile_country_code(value)
                                            }
                                            value={mobile_country_code}
                                            options={countryList.map(
                                                (item) => ({
                                                    label: `${item.emoji}  ${item.dial_code}`,
                                                    value: item.code_value,
                                                })
                                            )}
                                            className='w-24 h-12'
                                        ></Select>
                                        <Input
                                            type='tel'
                                            name='mobile'
                                            placeholder={t['mobile_no']}
                                            value={mobile}
                                            onChange={(e) =>
                                                handleMobileChange(
                                                    e.target.value
                                                )
                                            }
                                            className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                            autoComplete='off'
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {hotelDetails?.hotel_config?.required_guest_details.includes(
                                'name'
                            ) && (
                                <div className=' flex flex-row w-full'>
                                    <Input
                                        type='text'
                                        name='fullName'
                                        placeholder={
                                            t['login_name_placedholder']
                                        }
                                        value={fullName}
                                        isEmpty={lastName.length > 0}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                        className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                        required
                                    />
                                </div>
                            )}
                            <div className='flex justify-center items-center flex-col gap-6 mt-2'>
                                <label className='text-gray-400 italic text-md'>{t['login_page_disclaimer']}</label>
                                <div className={'flex'}>
                                    <Button type='primary' size='large' htmlType='submit' className='bg-theme'>
                                        {t['common_proceed_btn']}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};

export default LoginScreen;
