import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    LaundryCard,
    Header,
    Loading,
    Disclaimer,
    ModalTNC,
    Menu,
} from '../../components/components';
import localStorage from '../../services/cart';
import styles from './laundry.module.css';
import provider from './data.provider';
import LaundryAddon from '../../components/foodAddon/laundryAddon';
import ReactGA from 'react-ga4';
import { useLanguage } from '../../context/LanguageContext';
import { useHotel } from '../../context/HotelContext';
import { Button } from 'antd';

const Laundry = ({
    items = [],
    flattenedItems = [],
    disclaimer,
    description,
    view_only,
}) => {
    const [selectedItems, selectItems] = useState([]);
    const [rerender, forceRender] = useState(Math.random());
    const [selectedAddonItem, setAddonItem] = useState(null);
    const [isDisclaimer, setisDisclaimer] = useState(false);
    const [showTnC, setShowTnC] = useState(false);
    const [menu, toggleMenu] = useState(false);
    const { translations } = useHotel();
    const sessionID = localStorage.initiate();
    const navigate = useNavigate();
    const { t } = useLanguage();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, []);

    if (!items.length) {
        return <Loading />;
    }

    const updateSelection = (quantity, id) => {
        const itemFromCatalogue = flattenedItems.find((item) => item.id === id);
        const alreadySelected = selectedItems.findIndex(
            (item) => item.id === itemFromCatalogue.id
        );

        if (alreadySelected == -1) {
            selectItems((contain) => [
                ...contain,
                { ...itemFromCatalogue, quantity: 1 },
            ]);
            return;
        }
        let mutableItems = selectedItems;
        const newQuantity = mutableItems[alreadySelected].quantity + quantity;

        if (!newQuantity) {
            selectItems((contain) => contain.filter((item) => item.id !== id));
            return;
        }
        mutableItems[alreadySelected].quantity = newQuantity;
        selectItems(mutableItems);
        return forceRender(Math.random());
    };

    const increaseQuantity = (quantity, item) => {
        forceRender(Math.random);
        setAddonItem(null);
        if (item.addons) {
            item.addons = item.addons.filter((item) => {
                return item.enabled === 1;
            });
        }
        if (item.addons.length) {
            setAddonItem(item);
            return;
        }
        const itemIndex = selectedItems?.findIndex((x) => x?.id === item.id);
        if (itemIndex > -1) {
            const s = selectedItems;
            s[itemIndex].quantity = s[itemIndex].quantity + 1;
            selectItems(s);
            return;
        }
        const i = { ...item, quantity: quantity };
        selectItems([...selectedItems, i]);
        // localStorage.modify(sessionID, {
        //   ...item,
        //   quantity,
        // });
    };
    const increaseQuantityByAddons = (quantity, item) => {
        setAddonItem(null);

        const price = item.price || item.addonPrice.price;
        // selectItems((contain) => [
        //   ...contain,
        //   { ...item, price, addons: item.addons, quantity: 1 },
        // ]);
        const i = { ...item, price, addons: item.addons, quantity: 1 };
        selectItems([...selectedItems, i]);
        // const selectedItems = { ...item, price, addons: item.addons, quantity: 1 };
        // localStorage.add(sessionID, itemToCheckout);
        return;
        // const itemToCheckout = { ...item, price, addons: item.addons, quantity: 1 };
        // localStorage.add(sessionID, itemToCheckout);
        // return;
    };

    const decreaseQuantity = (quantity, item) => {
        forceRender(Math.random);
        if (item.addons.length) {
            const s = selectedItems;
            s.splice(
                selectedItems?.findIndex((x) => x.id === item.id),
                1
            );
            selectItems(s);
            return;
        }

        if (quantity === 0) {
            const s = selectedItems;
            s.splice(
                selectedItems?.findIndex((x) => x.id === item.id),
                1
            );
            selectItems(s);
            return;
        }

        const i = selectedItems?.findIndex((x) => x.id === item.id);
        selectedItems[i].quantity = selectedItems[i].quantity - 1;
        selectItems(selectedItems);
    };
    const cartLengthAddons = () => {
        const sumCounter = selectedItems
            .map((i) => i.quantity)
            .reduce((a, b) => a + b);
        return sumCounter;
    };
    const openDisclaimer = () => {
        setisDisclaimer(!isDisclaimer);
    };
    const API_TARGET = 'without_sub_category_items';

    const getcategories = () => {
        const categories = items
            ?.filter((item) => item.enabled === 1)
            ?.map((category) => ({
                name: category.name,
                id: category.id,
            }));
        // const subcategoriesNamesAndIds = subcategories
        //   .flat()
        //   .map((subcategory) => ({ name: subcategory.name, id: subcategory.id }));
        return categories;
    };

    const menuCategogries = getcategories();

    // console.log('items for menu', menuCategogries)

    const renderLaundries = () => {
        return items.map((category) => {
            return (
                category.enabled === 1 && (
                    <div className={styles.laundryService} key={Math.random()}>
                        <div className={styles.menuMainTitle}>
                            <h5 id={category.id}>{category.name}</h5>
                            {category.description && (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: category.description,
                                    }}
                                ></p>
                            )}
                        </div>
                        {category[API_TARGET].map(
                            (entry) =>
                                entry.enabled === 1 && (
                                    <LaundryCard
                                        {...entry}
                                        key={Math.random()}
                                        defaultQuantity={selectedItems.filter(
                                            (item) => item.id === entry.id
                                        )}
                                        increaseQuantity={(quantity) =>
                                            increaseQuantity(quantity, entry)
                                        }
                                        decreaseQuantity={(quantity) =>
                                            decreaseQuantity(quantity, entry)
                                        }
                                        view_only={
                                            view_only == 1 ? true : false
                                        }
                                    />
                                )
                        )}
                    </div>
                )
            );
        });
    };

    return (
        <>
            <Header
                showBack
                popupData={disclaimer}
                openDisclaimer={openDisclaimer}
                disclaimerText={disclaimer}
            />
            <div className={"laundryLayout-container " + styles.laundryLayout}>
                {menuCategogries.length > 2 && (
                    <div className={styles.menuButtonWrapper}>
                        {menu && (
                            <>
                                <div
                                    className={styles.overlay}
                                    onClick={() => toggleMenu(false)}
                                    id='Adesh1'
                                ></div>
                                <div
                                    className={styles.menuWrapper}
                                    style={{
                                        height:
                                            menuCategogries.length > 7
                                                ? '35vh'
                                                : `${menuCategogries.length}` *
                                                      32 +
                                                  4 +
                                                  'px',
                                    }}
                                >
                                    <Menu
                                        subcategories={menuCategogries}
                                        onClose={() => toggleMenu(false)}
                                    />
                                </div>
                            </>
                        )}
                        <Button
                            onClick={() => toggleMenu(!menu)}
                            type={'primary'}
                            className='menu-fixed-btn bg-theme'

                        >
                            {t['laundry_menu']}
                        </Button>
                    </div>
                )}

                {description && (
                    <p
                        className='mb-6'
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                )}
                {renderLaundries()}
            </div>
            {selectedItems.length ? (
                <div className={styles.cartCounterWrapper}>
                    <button
                        className='btn btn-checkout'
                        onClick={() =>
                            disclaimer
                                ? setShowTnC(true)
                                : navigate('/checkout-laundry', {
                                      state: { flattenedItems: selectedItems },
                                  })
                        }
                    >
                        <span>
                            {cartLengthAddons()} {t['item']}
                        </span>
                        <span>{t['laundry_review']}</span>
                    </button>
                </div>
            ) : (
                <div />
            )}
            {isDisclaimer && (
                <Disclaimer
                    onClose={() => openDisclaimer()}
                    disclaimerText={disclaimer}
                />
            )}
            {selectedAddonItem && (
                <LaundryAddon
                    foodItem={selectedAddonItem}
                    onClose={() => setAddonItem(null)}
                    translations={translations}
                    addSelected={(quantity, item) =>
                        increaseQuantityByAddons(quantity, item)
                    }
                />
            )}
            <ModalTNC
                title={t['laundry_terms_and_conditions']}
                body={disclaimer}
                btnSuccess={t['success_btn']}
                btnBack={t['back_btn']}
                onSuccess={() => {
                    navigate('/checkout-laundry', {
                        state: { flattenedItems: selectedItems },
                    });
                }}
                onClose={() => {
                    setShowTnC(false);
                }}
                open={showTnC}
                popupHeight={'450px'}
            />
        </>
    );
};

export default provider(Laundry);
